import { render, staticRenderFns } from "./about-us-page.vue?vue&type=template&id=17031a64&"
import script from "./about-us-page.vue?vue&type=script&lang=js&"
export * from "./about-us-page.vue?vue&type=script&lang=js&"
import style0 from "./about-us-page.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KButton from '@web/components/core/k-button'
import KCheckbox from '@web/components/core/k-checkbox'
import KIcon from '@web/components/core/k-icon'
import KInput from '@web/components/core/k-input'
import KLayout from '@web/components/core/k-layout'
import KLink from '@web/components/core/k-link'
import KTextarea from '@web/components/core/k-textarea'
installComponents(component, {KButton,KCheckbox,KIcon,KInput,KLayout,KLink,KTextarea})
